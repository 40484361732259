import React, { useContext,useState,useEffect } from "react"
import ReactDOM from "react-dom"
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import DataStore from './stores/data'
import { observer } from 'mobx-react-lite'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { toJS } from 'mobx'

import Xarrow from 'react-xarrows'


import IMG_CIRCULAR_ARROW from './media/img/twine/circularArrow.png'

import './twine.css'
import {
  Typography,
  Button,
  Container,
  Slide,
  Dialog,
  AppBar,
  Toolbar,
  IconButton
} from '@material-ui/core'
import {
 Close,
 GetApp
} from '@material-ui/icons'


const PLAYER_HEIGHT = 430

const useStyles = makeStyles(theme => ({
  spacing:{
    paddingTop:theme.spacing(6),
    paddingBottom:theme.spacing(6)
  },
  paddingTopBottom:{
    paddingTop:100,
    paddingBottom:100
  },
  [theme.breakpoints.down('sm')]:{
    paddingTopBottom:{
      paddingTop:30,
      paddingBottom:30
    }
  },
  appBar: {
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  code:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    whiteSpace:'pre-wrap',
    height:'100%',
    paddingBottom:theme.spacing(2),
    marginTop:56,
  },
  passage:{
    cursor:'pointer !important',
    "&:hover":{
      background:'#fcefe2 !important',
    }
  },
  circularArrow:{
    maxWidth:'100%',
    position:'absolute',
    top:-20,
    left:-20,
    width:50,
  },
  arrowEnd:{
    markerEnd:'url(#endarrow)'
  },
  arrowStart:{
    markerStart:'url(#startarrow)'
  },
  downloadBtn:{
    position:'absolute',
    left:0,
    top:0
  }
}))


const TwineEmbed = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { story,handleOpen,setStory,setCurrentPid,getTwineEmbed,findArrows } = useContext(DataStore)

  const _handleOpen = (pid) => {
    setCurrentPid(pid)
    handleOpen()
  }

  useEffect(() => {
   const params = new URLSearchParams(window.location.search)
   const storyId = params.get('story')
   if (storyId) {
    getTwineEmbed(storyId)
   }
  },[])


  return (
      <div id="storyEditView" className="zoom-big" style={{width:'100%',height:'100%'}}>
        <div className="passages">
          {story && story.passages && story.passages.map((p,index) => {
            const unit = 'px'
            return <div
              id={p.pid}
              key={p.pid}
              onClick={()=> _handleOpen(p.pid)}
              className={`passage ${classes.passage}`}
              style={{left: p.left+unit, top:p.top+unit , width: p.width+unit, height: p.height+unit,overflow:(p.self?'inherit':'')}}>
              <div className="frame">
                <div className="tags">
                </div>
                <p className="title">
                  {p.pid == "1" && <><span className="badge-create"></span><br /></>}
                  <span className="text">{p.name}</span>
                </p>
                <p className="excerpt">
                  {p.code}
                </p>
             </div>
             {p.self && <img src={IMG_CIRCULAR_ARROW} className={classes.circularArrow} />}
            </div>
          })}
          <Arrows2 />
        </div>
        <CodeCopyier />
        <DownloadStory />
      </div>
  )
})

const DownloadStory = observer(() => {
  const classes = useStyles()

  const _download = () => {
   const params = new URLSearchParams(window.location.search)
   const storyId = params.get('story')
   if (storyId) {
     fetch('https://twinebox.bastelkram.ch/download?shortid='+storyId)
      .then((t) => {
        return t.blob().then((b)=>{
          let a = document.createElement("a")
          a.href = URL.createObjectURL(b)
          a.setAttribute("download", 'storiesinderschule.ch_twine_story_' + new Date() + '.html')
          a.click()
          a = null
        })
      })
    }
  }

  return (
    <IconButton className={classes.downloadBtn} onClick={_download}><GetApp/></IconButton>
  )
})


const Arrows = observer((props) => {
  const classes = useStyles()
  const { passages } = props
  const { arrows } = useContext(DataStore)

  const arrowOptions = {
    color:'#000',
    strokeWidth:2,
    curveness:0.8,
    path:'smooth'
  }

  return (
    arrows.map((arrow,index) => {
      return <Xarrow
        start={arrow.from}
        end={arrow.to}
        {...{...arrowOptions,...arrow.options}}
      />
    })
  )
})

const Arrows2 = observer((props) => {
  const classes = useStyles()
  const { arrows } = useContext(DataStore)
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink" className="links" style={{transform: 'scale(1)', width: '100%', height: '100%'}}>
     <defs>
      <marker id="startarrow" markerWidth="10" markerHeight="7" refX="3" refY="4" orient="auto">
       <polygon points="10 0, 10 7, 0 3.5" fill="#666" />
      </marker>
      <marker id="endarrow" markerWidth="10" markerHeight="7" refX="4" refY="4" orient="auto" markerUnits="strokeWidth">
       <polygon points="0 0, 10 3.5, 0 7" fill="#666" />
      </marker>
     </defs>
      {arrows.map(arrow => {
          return <path
           d={arrow.options.svgpath}
           className={`
             ${classes.arrowEnd}
             ${(arrow.options.showTail ? classes.arrowStart : '')}
           `}>
          </path>
      })}
    </svg>
  )
})

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CodeCopyier = observer(() => {
  const classes = useStyles();
  const { open,handleClose,currentElement } = useContext(DataStore)
  const createMarkup = () => { return {__html: currentElement.code}; }
  const { t } = useTranslation()


  return (
    <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
      <AppBar className={classes.appBar} position="sticky">
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <Close />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {currentElement && currentElement.name}
          </Typography>
          <CopyToClipboard text={(currentElement ? currentElement.code: '')}>
           <Button edge="end" color="inherit" onClick={handleClose}>
            {t('core.general.copy').toUpperCase()}
           </Button>
          </CopyToClipboard>

        </Toolbar>
      </AppBar>
      {currentElement && <pre>
        <code className={`${classes.code}`} dangerouslySetInnerHTML={createMarkup()} />
       </pre>
      }
    </Dialog>
  );
})

export default TwineEmbed
