import { createContext } from 'react'
import { makeAutoObservable, observable, computed, toJS } from 'mobx'
import AjaxClient from 'api/ajax'
import { getBoxToBoxArrow } from 'perfect-arrows'

class Data {

  constructor() {
    // Call it here
    makeAutoObservable(this)
  }

  open = false
  handleOpen = () => this.open = true
  handleClose = () => this.open = false

  story = { passages: [] }
  setStory = (story) => this.story = story
  currentPid = -1
  setCurrentPid = (pid) => this.currentPid = pid

  base64Story
  shortId = null
  setShortId = (sid) => this.shortId = sid
  frame = { width: 0, height: 0 }
  setFrame = (f) => this.frame = f
  framePadding = 16
  correctTopBy = 26
  correctLeftBy = 26

  arrows = []

  get embedCode() {
    return `<iframe src="https://twinebox.bastelkram.ch/embed?story=${this.shortId}" width="${this.frame.width}" height="${this.frame.height}" frameborder="0" style="min-width:261px;"></iframe>`
  }

  get currentElement() {
    return this.story.passages.filter(p => p.pid === this.currentPid)[0]
  }

  loadHTMLFile = (file) => {
    const reader = new FileReader();

    return new Promise(resolve => {
      reader.addEventListener('load', e => {
        resolve(e.target.result);
      });

      reader.readAsText(file, 'UTF-8');
    });
  }

  importHTMLFile = (files) => {
    this.loadHTMLFile(files[0]).then(this.convertTwineStory)
  }

  decryptPassage = (passage) => {
    const pid = passage.getAttribute('pid')
    const name = passage.getAttribute('name')
    const position = passage.getAttribute('position').split(',')
    const size = passage.getAttribute('size').split(',')
    const code = passage.textContent
    return { pid, name, left: position[0], top: position[1], width: size[0], height: size[1], code }
  }

  sanatizePassagePositions = (passages, frame) => {
    passages.map((p, index) => {
      p.left = (p.left - frame.minLeft < 0 ? 0 : p.left - frame.minLeft) + this.framePadding
      p.top = (p.top - frame.minTop < 0 ? 0 : p.top - frame.minTop) + this.framePadding / 2
    })
    return passages
  }

  convertTwineStory = (html) => {
    let nodes = document.createElement('div')
    nodes.innerHTML = html
    const storyElements = Array.from(nodes.querySelectorAll('tw-passagedata'))

    let passages = []
    for (let i = 0; i < storyElements.length; i++) {
      let passage = this.decryptPassage(storyElements[i])
      passages.push(passage)
    }
    const frame = this.findOptimalIframeSize(passages)
    this.setFrame(frame)
    passages = this.sanatizePassagePositions(passages, frame)

    const stringified = JSON.stringify({ passages, scrollTo: this.frame.upperPoint })
    this.base64Story = btoa(stringified)
    this.setShortId(null)
    this.makeTwineEmbed()
  }

  findOptimalIframeSize = (passages) => {
    const minTop = passages.reduce((prev, curr) => { return prev.top < curr.top ? prev : curr })
    const maxTop = passages.reduce((prev, curr) => { return prev.top > curr.top ? prev : curr })
    const minLeft = passages.reduce((prev, curr) => { return prev.left < curr.left ? prev : curr })
    const maxLeft = passages.reduce((prev, curr) => { return prev.left > curr.left ? prev : curr })

    const size = {
      width: (parseInt(maxLeft.left) - parseInt(minLeft.left) + parseInt(minTop.width) + this.framePadding * 2) + this.correctLeftBy * 2,
      height: (parseInt(maxTop.top) - parseInt(minTop.top) + parseInt(minTop.height) + this.framePadding) + this.correctTopBy * 2,
      minLeft: minLeft.left,
      minTop: minTop.top

    }
    return size
  }

  findPassageByName = (name, passages) => {
    return passages.filter(p => p.name === name)[0]
  }

  findPassageByPid = (pid, passages) => {
    const p = passages.filter(p => p.pid == pid)
    return p && p[0] ? p[0] : null
  }

  markDoubleArrows = (arrows) => {
    let removeValFromIndex = []

    for (let i = 0; i < arrows.length; i++) {
      const arrow = arrows[i]
      for (let j = i + 1; j < arrows.length; j++) {
        const connector = arrows[j]
        if (arrow.from === connector.to && connector.from === arrow.to) {
          arrow.options.showTail = true
          arrow.options.showHead = true
          removeValFromIndex.push(j)
          break;
        }
      }
    }

    arrows = arrows.filter((a, i) => !removeValFromIndex.includes(i))
    return arrows
  }

  markSelfArrows = (arrows, passages) => {
    return arrows.filter(arrow => {
      if (arrow.from === arrow.to) {
        arrow.options.self = true
        arrow.options.showTail = false
      } else {
        arrow.options.self = false
      }
      return arrow
    })
  }

  getSVGArrowPath = (p1, p2, flip = false) => {
    p1.height = parseInt(p1.height)
    p1.width = parseInt(p1.width)
    p2.height = parseInt(p2.height)
    p2.width = parseInt(p2.width)

    const arrow = getBoxToBoxArrow(
      p1.left,
      p1.top,
      p1.width,
      p1.height,
      p2.left,
      p2.top,
      p2.width,
      p2.height,
      {
        bow: 0.2,
        stretch: 0.5,
        stretchMin: 0,
        stretchMax: 0,
        padStart: 0,
        padEnd: 0,
        flip: flip,
        straights: true,
      }
    )

    const [sx, sy, cx, cy, ex, ey, ae, as, ec] = arrow
    return `M${sx},${sy} Q${cx},${cy} ${ex},${ey}`
  }

  addArrowOptions = (arrows, passages) => {
    arrows = arrows.map(arrow => {
      arrow.options = {}
      return arrow
    })
    arrows = this.markDoubleArrows(arrows)
    arrows = this.markSelfArrows(arrows, passages)

    arrows.map((arrow) => {
      if (arrow.options.self) {
        const passage = this.findPassageByPid(parseInt(arrow.from), passages)
        if (passage) {
          arrow.options.svgpath = `M${passage.left},${passage.top + 40} A25,25 0 1 1 ${passage.left},${passage.top}`
        }
      } else {
        const p1 = this.findPassageByPid(parseInt(arrow.from), passages)
        const p2 = this.findPassageByPid(parseInt(arrow.to), passages)

        if (p1 && p2) {
          arrow.options.svgpath = this.getSVGArrowPath(p1, p2)
        }
      }
    })

    return arrows
  }

  findArrows = (passages) => {
    const arrows = []
    for (let i = 0; i < passages.length; i++) {
      const passage = passages[i]

      const regex = /\[\[.+\]\]/g
      const matches = passage.code.match(regex)

      if (matches && matches.length > 0 && matches[0]) {
        matches.map((match, index) => {
          let s = match
          s = s.substring(2, s.length - 2)
          s = s.split('->')
          s = s[s.length - 1]
          s = s.replace(/\[/g, '')
          s = s.replace(/\]/g, '')
          s = s.trim()

          const connector = this.findPassageByName(s, passages)
          arrows.push({ from: passage.pid, to: connector.pid })
        })
      }
    }
    this.arrows = this.addArrowOptions(arrows, passages)
    return passages
  }

  correctPositionFromTopLeftTo = (story) => {
    story.passages.map(passage => {
      passage.top += this.correctTopBy
      passage.left += this.correctLeftBy
      return passage
    })
  }

  makeTwineEmbed = () => {
    if (this.base64Story) {
      AjaxClient.makeTwineEmbed(
        { base64Story: this.base64Story },
        (res) => {
          if (res && res.data.success) {
            this.setShortId(res.data.shortId)
          }
        }
      )
    }
  }

  getTwineEmbed = (shortId) => {
    if (shortId) {
      AjaxClient.getTwineEmbed(
        { shortId },
        (res) => {
          if (res && res.data.success) {
            const stringified = atob(res.data.story.data)
            const story = JSON.parse(stringified)
            this.correctPositionFromTopLeftTo(story)

            this.findArrows(story.passages)
            this.setStory(story)
          }
        }
      )
    }
  }

  addTwineReferer = () => {

  }



}


// decorate(Data, {
//   open: observable,
//   story: observable,
//   currentPid: observable,
//   currentElement: computed,
//   base64Story: observable,
//   embedCode: computed,
//   shortId: observable,
//   frame: observable,
//   arrows: observable
// })


const DataStore = createContext(new Data())
export default DataStore
