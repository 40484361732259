import React, { useEffect, useState, useCallback, useContext } from "react"
import ReactDOM from "react-dom"
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useDropzone } from 'react-dropzone'
import DataStore from './stores/data'
import { observer } from 'mobx-react-lite'
import MaterialUIVideo from 'material-ui-video'
import Alert from '@material-ui/lab/Alert';

import Kiste from './Kiste'
import IMG_UPLOAD_SUCCESS from 'media/img/TwineBotHands_Good.png'
import IMG_UPLOAD_FAILED from 'media/img/TwineBotHands_Creepy.png'

import {
  Collapse,
  Typography,
  Button,
  Container,
  Paper,
  Chip,
  Divider,
  TextField,
  Card,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery
} from '@material-ui/core'
import {
  green
} from '@material-ui/core/colors'
import { useTheme } from '@material-ui/core/styles'
import {
  Send, HelpOutline
} from '@material-ui/icons'
import './unlocker.css'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(2),
    textAlign: 'center',
  },
  inputContainer: {
    width: '100%',
    border: '3px dashed #ccc',
    padding: theme.spacing(5)
  },
  dragActive: {
    borderColor: green[500],
    boxShadow: '0px 0px 43px 0px rgba(76, 175, 80,0.75) inset;'
  },
  input: {
    opacity: 0,
    width: '100%',
    height: '100%'
  },
  inputText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  },
  paper: {
    width: '100%'
  },
  spacer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  logo: {
    transform: 'scaleY(-1)'
  },
  sideContainer: {
    height: '100vh'
  },
  side: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    textAlign: 'center',
    width: '100%'
  },
  spacer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  yourLastBoxes: {
    border: '5px solid #a9147f',
    background: '#a9147f',
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'left'
  },
  lastBoxes: {
    position: 'fixed',
    height: '100vh',
    minWidth: 180,
    top: 0,
    right: -25,
  },
  cachedKiste: {
    padding: theme.spacing(1),
    textAlign: 'left',
    cursor: 'pointer',
    margin: theme.spacing(1),
    border: '5px solid #a9147f',
    background: 'white',
    borderRadius: theme.spacing(1),
    '&:hover': {
      transform: 'translate(-30px, 0px)'
    }
  },
  box: {
    display: 'block',
    margin: '0 auto',
    maxWidth: 320,
    marginTop: -60
  },
  pinInput: {
    minWidth: 240,
    maxWidth: '80%',
    color: 'rgba(0,0,0,.8)',
  },
  makeNew: {
    minWidth: 240,
    maxWidth: '80%',
    display: 'block',
    margin: '0 auto',
    marginTop: theme.spacing(5)
  },
  reference: {
    position: 'fixed',
    bottom: theme.spacing(1),
    left: theme.spacing(1)
  },
  cardVideo: {
    width: '100%'
  },
  alert: {
    width: '100%',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    textAlign: 'left'
  },
  videoTutorialBtn: {
    fontSize: '0.75rem'
  },
  divider: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  chip: {
    margin: theme.spacing(0.5)
  }
}))

const Uploader = observer((props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { kiste, story, setView, views, readFromLocalStorage, validateCache, boxAnimationUI, addToLocalStorage } = useContext(DataStore)

  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up('md'))

  const _unlock = (pin) => {
    story.setInputValue(pin)
    story.unlock(pin, (shortid, kistenType, isValid) => {
      if (isValid) {
        boxAnimationUI.switchTo(boxAnimationUI.types.OPEN)
        kiste.get(shortid, kistenType, () => {
          if (kistenType === kiste.types.TEACHER) {
            addToLocalStorage(kiste.shortid, kiste.name)
          }

          props.history.push('/box')
          setView(views.KISTE)
        })
      } else {
        boxAnimationUI.switchTo(boxAnimationUI.types.CLOSED)
      }
    })
  }

  const goToNewKiste = () => props.history.push('/new')

  useEffect(() => {
    validateCache()
    const params = new URLSearchParams(window.location.search)
    if (params && params.get('pin')) {
      _unlock(params.get('pin'))
    }
  }, [])
  const cache = readFromLocalStorage()

  return (
    story.unlocked
      ?
      <Kiste />
      :
      <div>
        <Grid container className={`${classes.sideContainer} ${classes.side}`} >
          <Grid item xs={12} sm={6} md={6} className={classes.side} style={{ flexDirection: 'column', padding: 16 }}>

            <Typography variant="h1">{t('twinebox')}</Typography>
            <Typography variant="h4">{t('forSchools')}</Typography>

            <Grid container>

              <Grid item xs={12}>
                <ChestUnlocker unlock={_unlock} />
                <div className="textdivider"><h3><span>{t('or')}</span></h3></div>
                <Button variant="outlined" onClick={goToNewKiste} className={classes.makeNew}>{t('makeYourOwn')}</Button>
              </Grid>

              {cache && cache.length > 0 &&
                <div className={classes.lastBoxes}>
                  <Paper elevation={2} className={classes.yourLastBoxes}>
                    {t('yourLastBoxes')}
                  </Paper>
                  {cache.map(obj => {
                    return <CachedKiste access={obj} unlock={_unlock} />
                  })}
                </div>
              }
            </Grid>
          </Grid>

        </Grid>
        <ReferenceModal />
      </div>
  )
})

const ReferenceModal = observer((props) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      <IconButton className={classes.reference} onClick={handleOpen}><HelpOutline /></IconButton>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <a href="https://storiesinderschule.ch">
              <img src="https://bastelkram.ch/websitebuilder/storiesinderschule.ch/thumb_prov.png?v=2" style={{ width: '100%', height: 'auto' }} alt="storiesinderschule.ch" />
            </a>
            <a href="https://storiesinderschule.ch">storiesinderschule.ch</a>, eine Idee von Simon Junker aus dem Fellowship 2020/2021 der Schulinformatik, PHBern
          </DialogContentText>
        </DialogContent>
      </Dialog>

    </>
  )
})

const CachedKiste = observer((props) => {
  const { boxAnimationUI } = useContext(DataStore)
  const { access, unlock } = props
  const { pin, name } = access
  const classes = useStyles()

  const _unlock = (e) => {
    boxAnimationUI.switchTo(boxAnimationUI.types.OPEN)
    unlock(pin)
  }

  return (
    <Paper elevation={2} className={classes.cachedKiste} onClick={_unlock}>
      {name}
    </Paper>
  )
})

const ChestUnlocker = observer((props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { unlock } = props
  const { story, kiste, boxAnimationUI } = useContext(DataStore)
  const data = useContext(DataStore)
  const isValidPinFormat = story.isValidPinFormat(story.inputValue)

  const setValue = (e) => {
    story.setInputValue(e.target.value)
    if (story.isValidPinFormat(e.target.value)) {
      boxAnimationUI.switchTo(boxAnimationUI.types.HALF)
    }
  }
  const _unlock = (e) => {
    if (e.keyCode === 13 || e.which === 13) {
      unlock(e.target.value)
    }
  }

  return (
    <>
      <img
        src={boxAnimationUI.src}
        alt="box"
        className={`
        ${classes.box}
        animate__animated
        animate__${boxAnimationUI.animation}
        animate__delay-${boxAnimationUI.delay}s
      `}
      />
      <TextField
        variant="filled"
        value={story.inputValue}
        className={classes.pinInput}
        onChange={setValue}
        onKeyPress={_unlock}
        disabled={story.loading}
        placeholder={t('enterPin')}
        InputLabelProps={{ shrink: true }}
      /><br />
      <Button
        variant="outlined"
        className={classes.pinInput}
        style={{ background: '#a9147f', color: 'white', fontWeight: 'bold', visibility: (isValidPinFormat ? 'visible' : 'hidden') }} onClick={() => { unlock(story.inputValue) }}>
        {t('Enter')}
      </Button>
    </>
  )
})


export const Converter = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { story, storyViewerUI } = useContext(DataStore)
  const defaultConversion = { complete: false, success: false }
  const [conversion, setConversion] = useState(defaultConversion)
  const [meta, setMeta] = useState(null)

  useEffect(() => {
    return () => {
      setMeta(null)
    }
  }, [])

  const afterConversion = (d) => setMeta(d)
  const afterUpload = (success) => {
    setConversion({ complete: true, success: success })
    setTimeout(() => {
      if (success) {
        storyViewerUI.handleUploadModalClose()
      }
      setConversion(defaultConversion)
    }, 1800)
  }

  return (
    <div className={classes.root}>
      {conversion.complete && <ConversionFeedback success={conversion.success} />}
      {!conversion.complete &&
        <>
          <UploadField afterConversion={afterConversion} />

          {meta && meta.isArchive && <ArchiveUpload meta={meta} afterConversion={afterConversion} />}
          {meta && !meta.isArchive &&
            <>
              <Chip label={story.contentname} className={classes.chip} color="primary" variant="outlined" />
              <SingleUpload meta={meta} afterUpload={afterUpload} />
            </>
          }

          <VideoTutorialPublishFile />
        </>
      }
    </div>
  )
})

const SingleUpload = observer((props) => {
  const classes = useStyles()
  const { meta, afterUpload } = props
  const { t } = useTranslation()
  const { story } = useContext(DataStore)
  const [showUploadButton, setShowUploadButton] = useState(false)
  const onChange = (e) => {
    story.setFilename(e.target.value)
    if (story.filename && story.filename.length > 2) {
      setShowUploadButton(true)
    } else {
      setShowUploadButton(false)
    }
  }

  const _upload = () => {
    story.upload(afterUpload)
  }


  return (
    <>
      {meta.update && meta.similarNames &&
        <>
          <Alert variant="outlined" severity="info" className={classes.alert}>
            <Typography variant="subtitle1">{t('update')}</Typography>
            <Typography variant="subtitle2">{t('similar')}</Typography>
            <List style={{ width: '100%' }}>
              {meta.similarNames.map((existingStory) => (
                <ListItem button>
                  <ListItemText primary={existingStory.filename} />
                  <ListItemSecondaryAction>

                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Alert>
          <Typography variant="subtitle1">{t('or')}</Typography>
        </>
      }
      <Alert variant="outlined" severity="success" className={classes.alert}>
        <Typography variant="subtitle1">{t('whoDidThis')}</Typography>
        <Typography variant="subtitle2">{t('overwrite')}</Typography>
        <TextField
          fullWidth
          onChange={onChange}
          placeholder={t('filename')}
        />
      </Alert>
      {showUploadButton &&
        <>
          <Button onClick={_upload} variant="contained" color="primary" style={{ width: '100%' }}> {t('uploadNow')} </Button>
          <Divider className={classes.divider} />
        </>
      }
    </>
  )
})

const ArchiveUpload = observer((props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { story } = useContext(DataStore)
  const { meta, afterConversion } = props

  const _selectStory = (name) => {
    meta.archive.select(name)
    story.convertStoryNode(meta.archive.selectedStory, afterConversion)
  }

  return (
    <Alert variant="outlined" severity="info" className={classes.alert}>
      <Typography variant="subtitle1">{t('foundArchive')}</Typography>
      <Typography variant="subtitle2">{t('anArchive')}</Typography>
      <List component="nav" aria-label="main">
        {meta.archive.getNames().map((name, i) => {
          return <ListItem button onClick={() => _selectStory(name)}>
            <ListItemIcon>
              <i className="icon-TwineViewerSymb_Overview"></i>
            </ListItemIcon>
            <ListItemText primary={name} />
          </ListItem>
        })}
      </List>
    </Alert>
  )
})


const UploadField = observer((props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { story } = useContext(DataStore)
  const { afterConversion } = props

  const onDrop = useCallback((files) => { story.importHTMLFile(files, afterConversion) }, [])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <div {...getRootProps()} className={`${classes.inputContainer} ${(isDragActive ? classes.dragActive : '')}`} >
      <Typography variant="h6" className={classes.inputText}>{t('dragNDrop')}</Typography>
      <input {...getInputProps()} className={classes.input} />
    </div>
  )
})

const ConversionFeedback = observer((props) => {
  const { success } = props
  const { t } = useTranslation()

  return (
    <div>
      {
        success
          ?
          <img src={IMG_UPLOAD_SUCCESS} alt="" className="animate__animated animate__zoomIn" />
          :
          <img src={IMG_UPLOAD_FAILED} alt="" className="animate__animated animate__bounce" />
      }
      <Typography variant="h6">{t(success ? 'yay' : 'noYay')}</Typography>
    </div>
  )
})

const VideoTutorialPublishFile = observer((props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [expanded, setExpanded] = useState(false)
  const handleExpandClick = () => setExpanded(!expanded)

  return (
    <div>
      <Button onClick={handleExpandClick} color="secondary" className={classes.videoTutorialBtn}>{t('doPublishDontArchive')}</Button>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <div className={classes.cardVideo}>
          <MaterialUIVideo src="https://bastelkram.ch/websitebuilder/storiesinderschule.ch/doPublishDontArchive.mp4" width="100%" />
        </div>
      </Collapse>
    </div>
  )
})




export default Uploader
