import React, { useState, useEffect, useContext } from "react"
import ReactDOM from "react-dom"
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import DataStore from './stores/data'
import { observer, useObserver } from 'mobx-react-lite'
import { toJS } from 'mobx'
import { Converter, SuccessfullyUploaded } from './Uploader'
import moment from 'moment'
import seedrandom from 'seedrandom'
import 'moment/locale/de'
import { useHistory } from 'react-router-dom'
import Masonry from 'react-masonry-css'

import IMG_AVATAR_TRANSPARENT from 'media/img/avatar_transparent.png'
import IMG_ARROW_HINT from 'media/img/arrowHint.png'

import StoryViewer from './StoryViewer'
import {
  Container,
  Typography,
  Grid,
  TextField,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  CardActions,
  Collapse,
  Avatar,
  ButtonGroup,
  Divider,
  Fab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Paper,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction
} from '@material-ui/core'
import {
  red, deepPurple, deepOrange
} from '@material-ui/core/colors'
import {
  Code, PlayArrow, GetApp, Share, Add, HighlightOff, Favorite, FavoriteBorder, Send, Delete, HelpOutline, ChatBubbleOutline, ChatBubble
} from '@material-ui/icons'

import './icomoon.css'
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    transition: '.2s all ease',
    overflowX: 'hidden',
    background: 'white',
    minHeight: '100vh'
  },
  storyRoot: {
    position: 'relative',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    '&:hover': {
      boxShadow: 'rgb(0 0 0 / 20%) 0px 3px 3px -2px, rgb(0 0 0 / 14%) 0px 3px 4px 0px, rgb(0 0 0 / 12%) 0px 1px 8px 0px'
    }
  },
  uploaderContainer: {
    position: 'relative',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '100%',
    border: '1px solid #eee',
    minHeight: 289
  },
  uploader: {
    border: '2px solid #ccc',
    borderRadius: '50%',
    margin: '0 auto',
    color: '#ccc',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),
    cursor: 'pointer',
    '&:hover': {
      background: 'rgb(0 0 0 / 20%)',
      color: 'white'
    }
  },
  spacing: {
    margin: theme.spacing(2)
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: {
    minHeight: 128,
    alignItems: 'flex-start',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  media: {
    width: 100,
    height: 100,
    margin: '0 auto'
  },
  appBar: {
    backgroundColor: 'white',
    color: '#a9147f',
    top: 'inherit',
    boxShadow: 'none',
  },
  cardContainer: {
    width: '100%',
    margin: 0,
    paddingTop: 70
  },
  rightBottomFabBtn: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    backgroundColor: '#a9147f',
    color: '#fff'
  },
  sharePaper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    color: 'rgba(0, 0, 0, 0.54)',
    textAlign: 'center'
  },
  divider: {
    marginTop: theme.spacing(1),
  },
  emptyBox: {
    textAlign: 'center',
    color: 'rgba(0,0,0,.54)',
    fontSize: '3rem',
    marginBottom: 100
  },
  commentsList: {
    background: '#a9157f',
    color: 'white'
  },
  commentCount: {
    position: 'absolute',
    color: 'white',
    fontSize: '1rem'
  },
  contentname: {

  },
  avatar: {
    background: 'transparent'
  },
  myMasonryGrid: {
    display: 'flex',
    width: 'auto',
  },
  myMasonryGrid_column: {

  },
  arrowHintUpper: {
    position: 'absolute',
    top: '10vh',
    left: '2vw',
    maxWidth: 275,
    transform: 'scaleX(-1) rotate(290deg)',
    opacity: 0.2
  },
  arrowHintLower: {
    position: 'absolute',
    bottom: '3vh',
    right: '6vw',
    maxWidth: 275,
    transform: 'rotate(5deg)',
    opacity: 0.2
  },
  arrowHintLowerSuS: {
    position: 'absolute',
    bottom: '-90vh',
    right: '6vw',
    maxWidth: 275,
    transform: 'rotate(5deg)',
    opacity: 0.2
  },
  forYou: {
    position: 'absolute',
    top: 180,
    left: 170,
    fontSize: '1.5rem',
    opacity: 0.4
  },
  forYourPupils: {
    position: 'absolute',
    bottom: 170,
    right: 60,
    fontSize: '1.5rem',
    opacity: 0.4
  },
  arrowHintLowerSuSText: {
    position: 'absolute',
    bottom: '-60vh',
    right: 60,
    fontSize: '1.5rem',
    opacity: 0.4,
    color: 'black'
  },
  comicCard: {
    position: 'relative',
    border: '5px solid #a9147f',
    margin: theme.spacing(1),
    background: 'white',
    borderRadius: theme.spacing(1),
    marginTop: theme.spacing(8)
  },
  comicCardHeader: {
    background: '#a9147f',
    height: 50,
    textAlign: 'center',
    fontSize: '1.5rem',
    color: 'white',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingTop: 5,
    position: 'relative'
  },
  comicCardHeaderText: {
    position: 'absolute',
    margin: '0 auto',
    left: 0,
    right: 0,
    zIndex: 2
  },
  comicCardAvatar: {
    position: 'absolute',
    maxWidth: 80,
    left: '5%',
    top: -50,
    zIndex: 1
  },
  comicCardFeedback: {
    position: 'absolute',
    right: 0,
    top: -45
  },
  comicCardActions: {
    background: '#fafafa',
    height: '100%',
    textAlign: 'center',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  }
}))


const Kiste = observer((props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { io } = useContext(DataStore)

  useEffect(io.init, [])

  return (
    <div className={classes.root}>
      <KisteWithStories />
    </div>
  )
})

const UploadModal = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { storyViewerUI, kiste } = useContext(DataStore)

  return (
    <>
      <Fab className={classes.rightBottomFabBtn} onClick={storyViewerUI.handleUploadModalOpen}>
        <Add />
      </Fab>
      {kiste.stories.length < 5 &&
        <>
          <img src={IMG_ARROW_HINT} alt="" className={classes.arrowHintLowerSuS} />
          <Typography variant="body2" className={classes.arrowHintLowerSuSText}>{t('uploadMyStory')}</Typography>
        </>
      }
      <Dialog onClose={storyViewerUI.handleUploadModalClose} open={storyViewerUI.uploadModalOpen}>
        <Converter />
      </Dialog>
    </>
  )
})

const KisteWithStories = observer((props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { kiste } = useContext(DataStore)

  const isTeacher = kiste.type === kiste.types.TEACHER
  const isSuS = kiste.type === kiste.types.SUS

  return (
    <>
      <AppBar className={classes.appBar}>
        <Toolbar>
          {isTeacher &&
            <>
              <TeacherHelp />
              <SuSShareModal />
            </>
          }
          {isSuS && !kiste.locked && <UploadModal />}
          <Typography variant="h4" className={classes.title} style={{ fontWeight: 'bold' }}>{kiste.name}</Typography>
          <Typography variant="h5" style={{ fontWeight: 'bold' }}>{kiste.pin}</Typography>
        </Toolbar>
      </AppBar>

      <div className={classes.cardContainer}>
        <Stories />
      </div>

      {kiste.stories.length === 0 && isTeacher && <TeacherWelcome />}
      <DeleteStoryModal />
      <CommentsDialog />

    </>
  )
})

const SuSShareModal = observer((props) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const handleClose = () => setOpen(false)
  const handleOpen = () => setOpen(true)

  return (
    <>
      <Fab className={classes.rightBottomFabBtn} onClick={handleOpen}>
        <Share />
      </Fab>
      <Dialog onClose={handleClose} aria-labelledby="share-modal-sus" open={open}>
        <DialogContent>
          <SuSShare noElevate />
        </DialogContent>
      </Dialog>
    </>
  )
})

const Stories = observer((props) => {
  const classes = useStyles()
  const { kiste } = useContext(DataStore)

  const breakpoints = {
    320: 1, // xs
    600: 2, // sm
    960: 3,// md
    1280: 6,// lg
    1920: 6// xl
  }

  return useObserver(() => (
    <Masonry
      breakpointCols={breakpoints}
      className={classes.myMasonryGrid}
      columnClassName={classes.myMasonryGrid_column}
    >
      {kiste.stories && kiste.stories.map((story) => {
        return <Story2 data={story} key={story.shortid} {...props} />
      })
      }
    </Masonry>
  ))
})



const TeacherWelcome = observer((props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Grid container alignItems="center" justify="center" style={{ height: '100vh' }} spacing={2}>
      <Grid item xs={12}>
        <img src={IMG_ARROW_HINT} alt="" className={classes.arrowHintUpper} />
        <Typography variant="body2" className={classes.forYou}>{t('forYou')}</Typography>
        <Typography variant="h4" className={classes.emptyBox}>{t('emptyBox')}</Typography>
        <img src={IMG_ARROW_HINT} alt="" className={classes.arrowHintLower} />
        <Typography variant="body2" className={classes.forYourPupils}>{t('forYourPupils')}</Typography>
      </Grid>
    </Grid>
  )
})

const TeacherHelp = observer((props) => {
  const [open, setOpen] = useState(false)
  const handleClose = () => setOpen(false)
  const handleOpen = () => setOpen(true)
  return (
    <>
      <IconButton edge="start" color="inherit" aria-label="share-helper" onClick={handleOpen}>
        <HelpOutline />
      </IconButton>
      <Dialog onClose={handleClose} aria-labelledby="share-helper" open={open}>
        <DialogContent>
          <TeacherShare noElevate />
        </DialogContent>
      </Dialog>
    </>
  )
})

const SuSShare = observer((props) => {
  const { noElevate } = props
  const classes = useStyles()
  const { t } = useTranslation()
  const { kiste } = useContext(DataStore)
  return (
    <Paper elevation={noElevate ? 0 : 3} className={classes.sharePaper}>
      <Typography variant="h5">{t('forPupils')}</Typography>
      <Divider className={classes.divider} /><br />
      <Typography variant="h6">{t('visit')}</Typography><br />
      <Typography variant="h4">{window.location.hostname}</Typography><br />
      <Typography variant="h6">{t('andEnter')}</Typography><br />
      <Typography variant="h4">{kiste.pin}</Typography><br />
      <Typography variant="h6">{t('shareSuS')}</Typography>
    </Paper>
  )
})

const TeacherShare = observer((props) => {
  const { noElevate } = props
  const classes = useStyles()
  const { t } = useTranslation()
  const { kiste } = useContext(DataStore)

  return (
    <Paper elevation={noElevate ? 0 : 3} className={classes.sharePaper}>
      <Typography variant="h5">{t('forTeachers')}</Typography>
      <Divider className={classes.divider} /><br />
      <Typography variant="h6">{t('youAreHere')}</Typography><br />
      <Typography variant="h4">{window.location.hostname}</Typography><br />
      <Typography variant="h6">{t('andMemorize')}</Typography><br />
      <Typography variant="h4">{kiste.shortid}</Typography><br />
      <Typography variant="h6">{t('shareTeacher')}</Typography>
      {noElevate &&
        <>
          <Divider className={classes.divider} /><br />
          <Button color="secondary" onClick={kiste.delete}>{t('deleteKiste')}</Button><br />
          <Typography variant="caption">({t('noMoreAsking')})</Typography>
        </>
      }
    </Paper>
  )
})


const DeleteStoryModal = observer(() => {
  const { kiste, storyViewerUI, story } = useContext(DataStore)
  const { currentStoryViewer } = kiste
  const { t } = useTranslation()

  const _deleteStory = () => {
    story.delete(
      currentStoryViewer.shortid,
      () => {
        storyViewerUI.handleDeleteModalClose()
      }
    )
  }

  return (
    <Dialog onClose={storyViewerUI.handleDeleteModalClose} open={storyViewerUI.deleteModalOpen}>
      <DialogTitle>{currentStoryViewer ? currentStoryViewer.filename : ''}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('deleteStoryReally')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={storyViewerUI.handleDeleteModalClose} color="primary">
          {t('close')}
        </Button>
        <Button onClick={_deleteStory} color="secondary">
          {t('deleteStory')}
        </Button>
      </DialogActions>
    </Dialog>
  )
})

const Story2 = observer((props) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { data } = props
  const classes = useStyles()
  const { storyViewerUI, kiste, commentsUI } = useContext(DataStore)
  const _like = () => {
    data.like(kiste.shortid)
  }

  const _popStory = (mode) => {
    data.load(
      (d) => {
        kiste.setCurrentStoryViewer(data)
        storyViewerUI.setViewmode(mode)
        storyViewerUI.handleOpen()
        history.push('/storyviewer')
      }
    )
  }

  const _popComments = () => {
    commentsUI.setData(data)
    commentsUI.handleOpen()
  }

  const _deleteStory = () => {
    kiste.setCurrentStoryViewer(data)
    storyViewerUI.handleDeleteModalOpen()
  }

  const _download = () => {
    fetch('https://twinebox.bastelkram.ch/embed?shortid=' + data.shortid)
      .then((t) => {
        return t.blob().then((b) => {
          let a = document.createElement("a")
          a.href = URL.createObjectURL(b)
          a.setAttribute("download", data.filename.split('.')[0])
          a.click()
          a = null
        })
      })
  }

  useEffect(() => {
    data.checkLiked(kiste.shortid)
  }, [data.likes])

  const color = '#a9147f'
  const avatar = data?.avatar ? data.avatar : '001'

  return (
    <div className={classes.comicCard}>
      <div className={classes.comicCardAvatar}>
        <img src={process.env.PUBLIC_URL + '/Avatare/Avatar_' + avatar + '.png'} style={{ transform: 'rotate(' + data.rotation + 'deg)', width: '100%' }} />
      </div>
      <div className={classes.comicCardFeedback}>
        {kiste.type === kiste.types.SUS &&
          <IconButton aria-label="like" onClick={_like} style={{ color: color, marginRight: -15 }}>
            {data.likes > 0 && <span style={{ fontSize: '1rem' }}>{data.likes}</span>} {data.liked ? <Favorite style={{ color: red[500] }} /> : <FavoriteBorder />}
          </IconButton>
        }
        <IconButton aria-label="comment" onClick={_popComments} style={{ color: color, marginRight: (kiste.type === kiste.types.TEACHER ? -15 : 0) }}>
          {data.comments.length > 0 ? <ChatBubble style={{ color: '#f5dc00' }} /> : <ChatBubbleOutline />}
        </IconButton>
        {kiste.type === kiste.types.TEACHER && <IconButton>
          <HighlightOff className={classes.deleteIt} style={{ color: color }} onClick={_deleteStory} />
        </IconButton>}
      </div>
      <div className={classes.comicCardHeader}>
        <div className={classes.comicCardHeaderText}> {data.filename} </div>
      </div>
      <div className={classes.comicCardActions}>
        {kiste.type === kiste.types.TEACHER &&
          <IconButton aria-label="code" onClick={() => _popStory(storyViewerUI.viewmodes.CODE)}>
            <i className="icon-TwineViewerSymb_Overview" style={{ color: color }}></i>
          </IconButton>
        }

        <IconButton aria-label="watch" onClick={() => _popStory(storyViewerUI.viewmodes.GAME)}>
          <i className="icon-TwineViewerSymb_Play" style={{ color: color }}></i>
        </IconButton>
        <IconButton aria-label="download" onClick={_download}>
          <i className="icon-TwineViewerSymb_Download" style={{ color: color }}></i>
        </IconButton>
      </div>
    </div>
  )
})

const Story = observer((props) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { data } = props
  const classes = useStyles()
  const { storyViewerUI, kiste } = useContext(DataStore)

  const [expanded, setExpanded] = useState(false)
  const handleExpandClick = () => setExpanded(!expanded)
  const handleExpandOpen = () => setExpanded(true)

  const _like = () => {
    data.like(kiste.shortid)
  }

  const _deleteComment = (cid) => {
    data.deleteComment(cid, kiste.shortid)
  }

  const _popStory = (mode) => {
    data.load(
      (d) => {
        kiste.setCurrentStoryViewer(data)
        storyViewerUI.setViewmode(mode)
        storyViewerUI.handleOpen()
        history.push('/storyviewer')
      }
    )
  }

  const _deleteStory = () => {
    kiste.setCurrentStoryViewer(data)
    storyViewerUI.handleDeleteModalOpen()
  }

  const _download = () => {
    fetch('https://twinebox.bastelkram.ch/embed?shortid=' + data.shortid)
      .then((t) => {
        return t.blob().then((b) => {
          let a = document.createElement("a")
          a.href = URL.createObjectURL(b)
          a.setAttribute("download", data.filename.split('.')[0])
          a.click()
          a = null
        })
      })
  }

  useEffect(() => {
    data.checkLiked(kiste.shortid)
  }, [data.likes])


  const seeder = seedrandom(data.filename)
  const color = storyViewerUI.getRandomColor(seeder)
  const style = { background: color.background, color: color.font }

  let rand = Math.floor(seeder() * 36) + 1
  rand = rand.toString().length === 1 ? '0' + rand : rand
  let rotation = Math.ceil(seeder() * 30) * (Math.round(seeder()) ? 1 : -1)
  const textAlign = { textAlign: 'left' }

  return (
    <Card className={classes.storyRoot} style={style}>
      <CardHeader
        subheader={data.filename}
        className={classes.cardHeader}
        style={{ ...style, ...textAlign }}
        avatar={<Avatar
          src={process.env.PUBLIC_URL + '/avatars/Avatar_' + rand + '.png'}
          className={classes.avatar}
          style={{ transform: 'rotate(' + rotation + 'deg)' }}
        />
        }
        action={kiste.type === kiste.types.TEACHER && <IconButton>
          <HighlightOff className={classes.deleteIt} onClick={_deleteStory} />
        </IconButton>
        }
      />


      <Divider />
      <CardActions style={{ justifyContent: 'center' }}>
        {data.comments.length > 0 &&
          <IconButton aria-label="like" onClick={handleExpandClick}>
            <ChatBubbleOutline /> {data.comments.length > 0 && <span style={{ fontSize: '1rem' }}>{data.comments.length}</span>}
          </IconButton>
        }
        {kiste.type === kiste.types.SUS &&
          <IconButton aria-label="like" onClick={_like}>
            {data.liked ? <Favorite style={{ color: red[500] }} /> : <FavoriteBorder />} {data.likes > 0 && <span style={{ fontSize: '1rem' }}>{data.likes}</span>}
          </IconButton>
        }
        {kiste.type === kiste.types.TEACHER &&
          <IconButton aria-label="code" onClick={() => _popStory(storyViewerUI.viewmodes.CODE)}>
            <i className="icon-TwineViewerSymb_Overview"></i>
          </IconButton>
        }

        <IconButton aria-label="watch" onClick={() => _popStory(storyViewerUI.viewmodes.GAME)}>
          <i className="icon-TwineViewerSymb_Play"></i>
        </IconButton>
        <IconButton aria-label="download" onClick={_download}>
          <i className="icon-TwineViewerSymb_Download"></i>
        </IconButton>
      </CardActions>
      <Divider />
      <CardActions>
        <CommentField count={data.comments.length} kistenId={kiste.shortid} story={data} handleExpandClick={handleExpandClick} handleExpandOpen={handleExpandOpen} />
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <div style={style}>
          {data.comments && data.comments.length > 0 &&
            <List dense style={{ width: '100%' }}>
              {toJS(data.comments).reverse().map((comment, i) => (
                <Comment key={'comment-' + i} comment={comment} deleteComment={_deleteComment} />
              ))}
            </List>
          }
        </div>
      </Collapse>
    </Card>
  )
})

const CommentsDialog = observer((props) => {
  const { commentsUI, kiste } = useContext(DataStore)

  const _deleteComment = (cid) => {
    commentsUI.content.deleteComment(cid, kiste.shortid)
  }

  return (
    <Dialog onClose={commentsUI.handleClose} aria-labelledby="simple-dialog-title" open={commentsUI.open}>
      <DialogTitle id="simple-dialog-title">{commentsUI.owner}</DialogTitle>
      {commentsUI.comments && commentsUI.comments.length > 0 &&
        <List dense style={{ width: '100%', minWidth: 320 }}>
          {toJS(commentsUI.comments).reverse().map((comment, i) => (
            <Comment key={'comment-' + i} comment={comment} deleteComment={() => _deleteComment(comment.id)} />
          ))}
        </List>
      }
      <CommentField
        count={commentsUI.comments.length}
        kistenId={kiste.shortid}
        story={commentsUI.content}
      />
    </Dialog>
  )
})

const Comment = observer((props) => {
  const { kiste } = useContext(DataStore)
  const { comment, deleteComment } = props

  const calcTime = (timestamp) => {
    return moment(timestamp).locale('de').fromNow()
  }
  const [time, setTime] = useState(calcTime(comment.created))
  const [iv, setIv] = useState(null)


  const _deleteComment = () => {
    deleteComment(comment.id)
  }

  useEffect(() => {
    setIv(setInterval(() => {
      setTime(calcTime(comment.created))
    }, 60 * 1000))
  }, [])

  return (
    <ListItem>
      <ListItemText primary={time} secondary={comment.text} />
      {kiste.type === kiste.types.TEACHER &&
        <ListItemSecondaryAction>
          <IconButton edge="end" aria-label="delete" onClick={_deleteComment}>
            <Delete />
          </IconButton>
        </ListItemSecondaryAction>
      }
    </ListItem>
  )
})

const CommentField = observer((props) => {
  const classes = useStyles()
  const { kistenId, story, handleExpandOpen } = props
  const { t } = useTranslation()
  const [comment, setComment] = useState("")

  const _setComment = (e) => {
    setComment(e.target.value)
  }
  const _comment = () => {
    story.comment(comment, kistenId)
    setComment("")
  }
  const _keyPress = (e) => {
    if (e.keyCode === 13 || e.which === 13) {
      _comment()
    }
  }

  return (
    <TextField
      placeholder={t('comment')}
      multiline
      value={comment}
      onChange={_setComment}
      onKeyPress={_keyPress}
      maxLength={255}
      fullWidth
      style={{ height: 50, padding: 10 }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" >
            <IconButton onClick={_comment}> <Send /> </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
})





export default Kiste
